import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { useCollection } from '../context/collection';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#fff',
    fontSize: '13px',
		backgroundColor: '#e53631',
  },
  countdownContainer: {
    backgroundColor: '#e53631'
  }
}));

const QuantityCounter = (props) => {
  const classes = useStyles();

  return (
    <div
      className="grid-countdown__main"
    >
      <span className={clsx(classes.title, 'grid-countdown__timer')}>{props.product.totalInventory === 0 ? 'SOLD OUT' : 'Limited '}</span>
      <div
        className={clsx(classes.countdownContainer, 'grid-countdown__timer')}
      >
        <span className='grid-countdown__main-counter'>
          {props.product.totalInventory}
        </span>
      </div>
    </div>
  )

};

export default QuantityCounter;
